import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Hidden from '@mui/material/Hidden';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import PageTitle from './PageTitle';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import { IconButton } from '@mui/material';
import { Instagram } from '@mui/icons-material';

function App() {

    const windowTheme = useTheme();
    const isSmallScreen = useMediaQuery(windowTheme.breakpoints.down('md'));

    React.useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

  return (
    <Box sx={{ marginBottom: 2 }}>
      <Box sx={{
          display: 'flex', 
          flexWrap: isSmallScreen ? 'wrap' : 'unset', 
          justifyContent:'space-between', 
          height: isSmallScreen ? "unset" : "70vh",
          alignItems: 'center', 
          background: '#e6eaea'
        }}>
          <Box sx={{
              textAlign:'center', 
              width: ['-moz-available', '-webkit-fill-available'],
              order: isSmallScreen ? 3 : 2,
              marginBottom: isSmallScreen ? '32px' : 'unset', 
              }}>

            <Box style={{
                display: isSmallScreen ? 'flex' : 'block', 
                alignItems: isSmallScreen ? 'center' : 'unset', 
                marginBottom: isSmallScreen ? '16px' : 'unset', 
                paddingLeft: '16px',
                paddingRight: '16px',
                }}
            >
                <Hidden mdDown>
            <Box>
                <img src="./boxlogo.svg" alt="logo" width={isSmallScreen ? "90%" : "50%"} style={{maxWidth:360, maxHeight: '40vh'}} />
            </Box>
            </Hidden>
            <Typography sx={{width: '100%', marginTop:4, marginBottom:4, textAlign:'center'}} variant="h5" color="primary">Be confident in who you are <strong style={{ whiteSpace: 'nowrap' }}>every day</strong></Typography>
</Box>
<Link variant="body2" to="/contact" underline="none" component={RouterLink}>
    <Button variant="contained">Contact Lisa</Button>
</Link>
            

        </Box>
        <Box style={{
            marginTop: isSmallScreen ? '5vh' : '4px', 
            marginBottom: isSmallScreen ? '3vh' : 'unset', 
            textAlign: isSmallScreen ? 'center' : 'unset',
            justifyContent: isSmallScreen ? 'center' : 'unset',
            width: isSmallScreen ? '100%' : 'unset',
            order: isSmallScreen ? 2 : 3,
            }}>
            <Hidden mdDown>
                <Box sx={{ clipPath: 'polygon(10% 0%, 100% 0px, 100% 100%, 0% 100%)',  shapeOutside: 'polygon(10% 0%, 100% 0px, 100% 100%, 0% 100%)' }}>
                    <img src="./home.jpg" alt="logo" style={{height:"70vh"}} />
                </Box>
            </Hidden>
            <Hidden mdUp>
            <Box sx={{display: "inline-block", position: "relative", width: 240, height: 240, overflow: "hidden", borderRadius: "0%", borderColor: "#ffffff", borderWidth: 4, borderStyle: "solid" }}>
                    <img src="./home.jpg" alt="logo" style={{ width: "auto", height: "100%", marginLeft: -76, marginTop: 84, transform: "scale(2)"}} />
                </Box>
            </Hidden>
        </Box>
      </Box>
      <Container maxWidth="md">
          <PageTitle title="About Lisa" />
          <Typography variant="body1" sx={{marginBottom: 2}}>
                <strong>Lisa J</strong> Naumann is the Brisbane-based stylist behind Styled by Lisa J. She is passionate about helping women feel confident with their style. Lisa spent ten years studying and working in public health, specialising in skin cancer prevention. While on maternity leave with her first child, she was made redundant from a job she loved and become a stay-at-home-mum.
            </Typography>
            <Typography variant="body1" sx={{marginBottom: 2}}>
                Several years and three kids later she returned to public health but longed for a change. Lisa decided to combine her love of shopping and helping women build their confidence through fashion. After enrolling in courses from the <Link href="https://australianstyleinstitute.com.au/" target="_blank">Australian Style Institute</Link>, she discovered the world of styling and soon realised this is exactly where she is supposed to be!
            </Typography>
            <Typography variant="body1" sx={{marginBottom: 2}}>
                Lisa approaches styling with empathy, positivity and a deep understanding of the challenges women face, especially during and after pregnancy. She recognises the loss of confidence, change in identity and confusion around personal style while juggling everything parenthood involves.  
            </Typography>
            <Typography variant="body1" sx={{marginBottom: 2}}>
            Lisa can style women of all ages and believes that confidence starts with knowing how to balance your proportions and accepting your beautiful and unique body.
            </Typography>
            <Typography variant="body1" sx={{marginBottom: 2, fontSize:'1.125rem', fontWeight:500, marginLeft: 6, marginRight: 6, fontStyle: 'normal'}}>            
            "When women feel confident and self-assured, even for a moment in their day, they are unstoppable!"
            </Typography>
            <Typography variant="body1" sx={{marginBottom: 2}}>
                One of Lisa's favourites things to do is mix and match outfits and create new uses for the pieces in her wardrobe. She loves shopping and will do the research and leg-work for you to ensure you have a calm, positive shopping experience. 
            </Typography>
            <Typography variant="body1" sx={{marginBottom: 2}}>
                Styling packages include wardrobe makeovers, personalised shopping sessions and event styling. Follow Lisa <Link href="https://www.instagram.com/styledbylisaj/" target="_blank">@styledbylisaj</Link> for styling tips and outfit inspiration. If you are ready to invest in yourself and your style, Lisa would <Link to="/contact" component={RouterLink}>love to hear from you</Link>! 
            </Typography>
      </Container>
      <Box sx={{background: '#e6eaea', paddingTop: '1px', paddingBottom: 2, marginBottom: 4}}>
      <Container maxWidth="md">
          <PageTitle title="Packages &amp; Campaigns" colour="#e6eaea" />
          <Box sx={{ display: isSmallScreen ? 'block' : 'flex', justifyContent: 'space-evenly' }}>
          <Card sx={{ maxWidth: isSmallScreen ? '100%' : 345, display: 'grid' }}>
            <Box>
            <CardMedia
                component="img"
                alt="Hangers and clothes"
                height="140"
                image="./packages2.jpg"
            />
            <CardContent>
            <Link variant="body2" to="/packages" underline="none" component={RouterLink}>
            <Typography gutterBottom variant="h5" component="div">
                    Packages
                </Typography>
                </Link>
                
                <Typography variant="body2" color="text.secondary" sx={{marginBottom: 2}}>
                    Lisa has styling packages to suit every need. Whether you're looking for a full styling experience, a wardrobe makeover or an expert opinion on your next shopping trip, <Link variant="body2" to="/contact" underline="none" component={RouterLink}>contact Lisa</Link> now to discuss your styling needs.
                </Typography>
            </CardContent>
            </Box>
            <CardActions>
            <Link variant="body2" to="/packages" underline="none" component={RouterLink}>
                <Button size="small" >See more</Button>
                </Link>
            </CardActions>
            </Card>
            <Card sx={{ marginTop: isSmallScreen ? 2 : 'unset', maxWidth: isSmallScreen ? '100%' : 345, display: 'grid' }}>
                <Box>
            <CardMedia
                component="img"
                alt="Campaign image"
                height="140"
                image="./campaigns2.jpg"
            />
            <CardContent>
            
            <Typography gutterBottom variant="h5" component="div">
                    Campaigns
                </Typography>
            
                
                <Typography variant="body2" color="text.secondary">
                    Lisa's work in the editorial space includes styling and modelling for promotional campaigns as well as back of house assistance and runway styling. 
                </Typography>

            </CardContent>
            </Box>
            <CardActions sx={{alignItems: 'end'}}>
                <Box>

                    <Link component="a" href="https://www.instagram.com/styledbylisaj/" target="_blank" aria-label="Instagram" sx={{display: 'flex', alignItems: 'center', textDecoration: 'none', pl: 1, pb: 0.5}}>
                        <Typography variant="body2" sx={{fontSize: '13px', fontWeight: 500}} color="text.primary">SEE MORE ON&nbsp;</Typography>
                        <Instagram color="primary" />
                    </Link>
                </Box>
            </CardActions>
            </Card>
            </Box>
      </Container>
      </Box>

      { /*
      <Box>
      <Container maxWidth="md">
          <PageTitle title="Recent articles" />
          Recent bloggy stuff
      </Container>
      </Box>
        */ }
    </Box>
  );
    }

export default App;

import React from 'react';
import logo from './logo.svg';
import Header from './Header';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import PageTitle from './PageTitle';
import Package from './Package';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';

function Packages() {

    React.useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

  return (
    <Box>
        <PageTitle title="Packages" />

        <Container maxWidth="lg">

        <Typography sx={{marginBottom: 4}}>
        Lisa has styling packages to suit every need. Whether you're looking for a full styling experience, a wardrobe makeover or an expert opinion on your next shopping trip, <Link to="/contact" component={RouterLink}>contact Lisa</Link> now to discuss your styling needs.
        </Typography>

        <Grid container>
            <Grid item xs={12} md={6} sx={{marginBottom: 6, display:'flex', justifyContent: 'center'}} >
            <div style={{width:'100%', display: 'flex', justifyContent: 'center', alignItems: 'baseline'}}>
                <Package
                    title="The Wardrobe Edit"
                    price={350}
                    blurb="Do you have a closet full of clothes but nothing to wear? Do you struggle to mix and match outfits and make use of what you already own? After this session your wardrobe will be organised and only filled with the garments you truly love - helping you feel energised to get dressed each day."
                    inclusions={["1 hour style consultation", "3 hour wardrobe edit", "Shopping list"]}
                    totalEffort="Your time: 4 hours"
                    image="wardrobeedit2.jpg"
                    svg={<path fill="currentColor" d="M12 4A3.5 3.5 0 0 0 8.5 7.5H10.5A1.5 1.5 0 0 1 12 6A1.5 1.5 0 0 1 13.5 7.5A1.5 1.5 0 0 1 12 9C11.45 9 11 9.45 11 10V11.75L2.4 18.2A1 1 0 0 0 3 20H21A1 1 0 0 0 21.6 18.2L13 11.75V10.85A3.5 3.5 0 0 0 15.5 7.5A3.5 3.5 0 0 0 12 4M12 13.5L18 18H6Z" />}
                />
                </div>
            </Grid>
            <Grid item xs={12} md={6} sx={{marginBottom: 6, display:'flex', justifyContent: 'center'}} >
            <div style={{width:'100%', display: 'flex', justifyContent: 'center', alignItems: 'baseline'}}>
                <Package
                    title="The Shopping Trip"
                    price={400}
                    blurb="Do you already know what is in your wardrobe and what is missing but just need guidance and an expert eye while shopping? Perhaps you want to shop for the upcoming season but don't have time to browse. Let Lisa do the heavy lifting and transform the way you shop to make it effortless, purposeful and give you the return on investment you've been missing. Lisa will explore your current shopping challenges, what's missing in your wardrobe and items you're looking to purchase."
                    inclusions={["1 hour style consultation", "3 hour personalised shopping session at a shopping centre local to you (in Brisbane)"]}
                    totalEffort="Your time: 4 hours"
                    image="shoppingtrip2.jpg"
                    svg={<path fill="currentColor" d="M5.5,21C4.72,21 4.04,20.55 3.71,19.9V19.9L1.1,10.44L1,10A1,1 0 0,1 2,9H6.58L11.18,2.43C11.36,2.17 11.66,2 12,2C12.34,2 12.65,2.17 12.83,2.44L17.42,9H22A1,1 0 0,1 23,10L22.96,10.29L20.29,19.9C19.96,20.55 19.28,21 18.5,21H5.5M12,4.74L9,9H15L12,4.74M12,13A2,2 0 0,0 10,15A2,2 0 0,0 12,17A2,2 0 0,0 14,15A2,2 0 0,0 12,13Z" />}
                />
                </div>
            </Grid>
            <Grid item xs={12} md={6} sx={{ marginBottom: 6, display:'flex', justifyContent: 'center'}} >
            <div style={{width:'100%', display: 'flex', justifyContent: 'center', alignItems: 'baseline'}}>
                <Package
                    title="The Full Styling Experience"
                    price={900}
                    blurb="This is for the person who would love a complete styling makeover. Combining both <b>The Wardrobe Edit</b> and <b>The Shopping Trip</b>, you will gain full insight into your shape, proportions, clothing and colours that suit you plus learn how to shop efficiently and with purpose."
                    inclusions={["1 hour style consultation", "3 hour wardrobe edit", "3 hour personalised shopping session at a shopping centre local to you (in Brisbane)" /*, "1 hour wardrobe integration - to learn how to mix and match your new purchases within your existing wardrobe"*/, "Personalised style guide"]}
                    totalEffort="Your time: 7 hours"
                    image="packages2.jpg"
                    svg={<path fill="currentColor" d="M18.09 11.77L19.56 18.1L14 14.74L8.44 18.1L9.9 11.77L5 7.5L11.47 6.96L14 1L16.53 6.96L23 7.5L18.09 11.77M2 12.43C2.19 12.43 2.38 12.37 2.55 12.26L5.75 10.15L4.18 8.79L1.45 10.59C.989 10.89 .861 11.5 1.16 12C1.36 12.27 1.68 12.43 2 12.43M1.16 21.55C1.36 21.84 1.68 22 2 22C2.19 22 2.38 21.95 2.55 21.84L6.66 19.13L7 17.76L7.31 16.31L1.45 20.16C.989 20.47 .861 21.09 1.16 21.55M1.45 15.38C.989 15.68 .861 16.3 1.16 16.76C1.36 17.06 1.68 17.21 2 17.21C2.19 17.21 2.38 17.16 2.55 17.05L7.97 13.5L8.24 12.31L7.32 11.5L1.45 15.38Z" />}
                />
                </div>
            </Grid>
            <Grid item xs={12} md={6} sx={{marginBottom: 6, display:'flex', justifyContent: 'center'}} >
                <div style={{width:'100%', display: 'flex', justifyContent: 'center', alignItems: 'baseline'}}>
                <Package
                    title="Event styling"
                    price={400}
                    blurb="Do have a special event coming up but have no idea what to wear and how to complete your look? It could be a wedding, special birthday, anniversary, ball or school formal. Lisa can help you with choosing your perfect outfit, advise on matching accessories, hairstyle, makeup and shoes. You won't have to think of a thing!"
                    inclusions={["1 hour style consultation", "3 hour personalised shopping session at a shopping centre local to you (in Brisbane)"]}
                    totalEffort="Your time: 4 hours"
                    image="eventstyling2.jpg"
                    svg={<path fill="currentColor" d="M7.5,7L5.5,5H18.5L16.5,7M11,13V19H6V21H18V19H13V13L21,5V3H3V5L11,13Z" />}
                />
                </div>
            </Grid>
        </Grid>
        </Container>
    </Box>
  );
}

export default Packages;
